import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { modifyUser } from "../../../store/thunks/userThunk";
import { fetchClinics } from "../../../store/thunks/clinicThunk";

const UserClinicInformation = ({ user }) => {
    const dispatch = useDispatch();
    const clinics = useSelector((state) => state.clinics.clinics);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchClinics()).then(() => setLoading(false));
    }, [dispatch]);

    const formik = useFormik({
        initialValues: {
            clinics: user.clinics || [],
        },
        validationSchema: Yup.object({
            clinics: Yup.array().min(1, "Please select at least one clinic"),
        }),
        onSubmit: (values) => {
            dispatch(modifyUser({ userId: user._id, userData: values }));

        },
    });

    if (loading) {
        return <div>Loading clinics...</div>;
    }

    return (
        <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Clinics
                </label>
                <div className="mt-1">
                    {clinics.map((clinic) => (
                        <div key={clinic._id} className="flex items-center">
                            <input
                                id={`clinic-${clinic._id}`}
                                name="clinics"
                                type="checkbox"
                                value={clinic._id}
                                checked={formik.values.clinics.includes(
                                    clinic._id
                                )}
                                onChange={formik.handleChange}
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label
                                htmlFor={`clinic-${clinic._id}`}
                                className="ml-3 block text-sm font-medium text-gray-700"
                            >
                                {clinic.name}
                            </label>
                        </div>
                    ))}
                </div>
                {formik.touched.clinics && formik.errors.clinics ? (
                    <div className="text-red-500 text-sm mt-1">
                        {formik.errors.clinics}
                    </div>
                ) : null}
            </div>

            <div className="pt-5">
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
};

export default UserClinicInformation;
