import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../store/thunks/userThunk';
import PersonalInformation from './components/UserPersonalInformation';
import FinancialInformation from './components/UserFinancialInformation';
import ClinicInformation from './components/UserClinicInformation';
import HRInformation from './components/UserHrInformation';
import Availability from './components/UserAvailabilityInformation';
import { logInfo } from '../../../src/utils/logger';

const UserDetails = ({ user, onClose }) => {
  const [activeTab, setActiveTab] = useState('personal');
  const { status, error } = useSelector((state) => state.users);

  useEffect(() => {
    logInfo('UserDetails component rendered', {
      userId: user._id,
      activeTab: activeTab,
      componentName: 'UserDetails'
    });
  }, [user, activeTab]);

  const tabs = [
    { id: 'personal', name: 'Personal Information', component: PersonalInformation },
    { id: 'financial', name: 'Financial Information', component: FinancialInformation },
    { id: 'clinic', name: 'Clinic Information', component: ClinicInformation },
    { id: 'hr', name: 'HR Information', component: HRInformation },
    { id: 'availability', name: 'Availability', component: Availability },
  ];

  if (status === 'loading') return <div>Loading...</div>;
  if (status === 'failed') return <div>Error: {error}</div>;
  if (!user) return <div>No user found</div>;

  const ActiveTabComponent = tabs.find(tab => tab.id === activeTab)?.component;
  
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 bg-white rounded-lg shadow">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">User Details</h1>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`
                ${activeTab === tab.id
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }
                whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
              `}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-6">
        {ActiveTabComponent && <ActiveTabComponent user={user} />}
      </div>
    </div>
  );
};

export default UserDetails;
