import React, { useState } from "react";
import StatusLights from "./StatusLights";
import WideSidePanel from "./WideSidePanel"; // Ensure this import is correct

export default function ToDoItem({ todo, onReschedule, onWideSidePanelClose }) {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [panelTitle, setPanelTitle] = useState("");

  const handleActionClick = () => {
    setPanelTitle("Appointment Details");
    setIsPanelOpen(true);
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    return `${date.toLocaleDateString(
      "en-US",
      options
    )}, ${date.toLocaleTimeString("en-US", timeOptions)}`;
  };

  const handleClosePanel = () => {
    setIsPanelOpen(false);
    if (onWideSidePanelClose) {
      onWideSidePanelClose(); // Call the passed function
    }
  };

  return (
    <>
      <li className="relative flex items-center space-x-4 py-4">
        <div className="flex-auto min-w-0">
          <div className="flex items-center gap-x-1.5">
            <StatusLights appointment={todo} />
            <h2 className="flex-1 min-w-0 text-sm font-semibold leading-6 text-gray-900">
              <div className="flex">
                <span className="truncate">{todo.todo}</span>
              </div>
            </h2>
          </div>
          <div className="flex justify-between">
            <div className="mt-3 text-xs leading-5 text-gray-500">
              <p>
                {todo.client?.firstName} {todo.client?.lastName} -{" "}
                {todo.service?.description}
              </p>
              <p>{formatDateTime(todo.start)}</p>
            </div>
            <div className="flex-shrink-0 flex items-end space-x-2 mt-3">
              <button
                onClick={handleActionClick}
                className="rounded-full px-2 py-1 text-xs font-medium bg-gray-100 hover:bg-gray-200">
                View
              </button>
            </div>
          </div>
        </div>
      </li>
      {isPanelOpen && (
        <WideSidePanel
          title={panelTitle}
          open={isPanelOpen}
          onClose={handleClosePanel} // Use handleClosePanel
          client={todo.client}
          appointment={todo}
          onReschedule={onReschedule}
        />
      )}
    </>
  );
}
