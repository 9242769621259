// src/store/slices/cliniciansSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  createClinician,
  fetchClinicians,
  fetchClinicianById,
  fetchTreatingClinician,
  fetchSupervisingClinician,
  updateClinician,
  deleteClinician,
} from "../thunks/cliniciansThunk";

const cliniciansSlice = createSlice({
  name: "clinicians",
  initialState: {
    clinicians: [],
    status: "idle",
    error: null,
    selectedClinician: null,
    selectedTreatingClinician: null,
    selectedSupervisingClinician: null,
  },
  reducers: {
    clearSelectedClinicians: (state) => {
      state.selectedTreatingClinician = null;
      state.selectedSupervisingClinician = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Clinician
      .addCase(createClinician.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createClinician.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clinicians.push(action.payload);
      })
      .addCase(createClinician.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch All Clinicians
      .addCase(fetchClinicians.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClinicians.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clinicians = action.payload;
      })
      .addCase(fetchClinicians.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch Clinician by ID
      .addCase(fetchClinicianById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClinicianById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedClinician = action.payload;
      })
      .addCase(fetchClinicianById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch Treating Clinician
      .addCase(fetchTreatingClinician.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTreatingClinician.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedTreatingClinician = action.payload;
      })    
      .addCase(fetchTreatingClinician.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch Supervising Clinician
      .addCase(fetchSupervisingClinician.pending, (state) => {
        state.status = "loading";
      })    
      .addCase(fetchSupervisingClinician.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedSupervisingClinician = action.payload;
      })
      .addCase(fetchSupervisingClinician.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Update Clinician
      .addCase(updateClinician.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateClinician.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.clinicians.findIndex(
          (clinician) => clinician._id === action.payload._id
        );
        if (index !== -1) {
          state.clinicians[index] = action.payload;
        }
      })
      .addCase(updateClinician.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Delete Clinician
      .addCase(deleteClinician.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteClinician.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clinicians = state.clinicians.filter(
          (clinician) => clinician._id !== action.meta.arg
        );
      })
      .addCase(deleteClinician.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearSelectedClinicians } = cliniciansSlice.actions;
export default cliniciansSlice.reducer;
