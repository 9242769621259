import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPayment, editPayment } from "../store/thunks/paymentsThunk";
import {
  fetchInvoiceById,
  updateInvoiceStatus,
} from "../store/thunks/invoiceThunk";
import { updateAppointmentStatus } from "../store/thunks/appointmentsThunk";

const InvoicePaymentModal = ({ onClose, invoice, payment }) => {
  const [paymentMethod, setPaymentMethod] = useState(
    payment?.paymentMethod || ""
  );
  const [paymentAmount, setPaymentAmount] = useState(
    payment?.amount || invoice?.amount || 0
  );
  const [paymentDate, setPaymentDate] = useState(
    payment?.paymentDate || new Date().toISOString().split("T")[0]
  ); // Default to today

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (payment) {
      setPaymentMethod(payment.paymentMethod);
      setPaymentAmount(payment.amount);
      setPaymentDate(new Date(payment.paymentDate).toISOString().split("T")[0]); // Set date for existing payment
    }
  }, [payment]);

  const refetchInvoice = async () => {
    const invoiceResponse = await dispatch(
      fetchInvoiceById(invoice?._id)
    ).unwrap();
    console.log("Refetched Invoice:", invoiceResponse);
  };

  const handleSavePayment = async () => {
    if (!paymentMethod || paymentAmount <= 0) {
      setError("Both payment method and payment amount are required.");
      return;
    }

    try {
      let paymentResponse;
      if (payment) {
        // Edit existing payment
        paymentResponse = await dispatch(
          editPayment({
            paymentId: payment._id,
            paymentMethod,
            paymentAmount,
            paymentDate: new Date(paymentDate).toISOString(),
          })
        ).unwrap();
      } else {
        // Create new payment
        paymentResponse = await dispatch(
          createPayment({
            invoiceId: invoice?._id,
            paymentMethod,
            paymentAmount,
            paymentDate: new Date(paymentDate).toISOString(),
          })
        ).unwrap();
      }

      console.log("Payment Response:", paymentResponse);

      if (paymentResponse) {
        alert(
          payment
            ? "Payment updated successfully!"
            : "Payment created successfully!"
        );

        if (!payment) {
          await dispatch(
            updateInvoiceStatus({ id: invoice?._id, status: "paid" })
          ).unwrap();

          const appointmentId = invoice.appointmentId;
          await dispatch(
            updateAppointmentStatus({
              appointmentId,
              status: "Invoice Paid",
              comments: "Status changed to Invoice Paid",
              userId: user._id,
            })
          ).unwrap();

          await refetchInvoice();
        }
      }
    } catch (error) {
      console.error(
        "Error processing payment or updating invoice status:",
        error.response ? error.response.data : error
      );

      if (error) {
        setError("This invoice has already been paid.");
      } else {
        setError("Failed to process payment. Please try again.");
      }
    } finally {
      onClose();
    }
  };

  // Determine if the invoice is a parent or a child based on the invoiceId suffix
  const isParentInvoice = invoice?.invoiceId?.endsWith("B");
  const isChildInvoice = invoice?.invoiceId?.endsWith("A");

  // Derive the sibling invoice ID for display (switch between A and B)
  const siblingInvoiceId = isParentInvoice
    ? `${invoice?.invoiceId?.slice(0, -1)}A` // Change last character to 'A'
    : `${invoice?.invoiceId?.slice(0, -1)}B`; // Change last character to 'B'

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg max-w-5xl w-full p-6 relative">
        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-4">
          {payment ? "Edit Payment" : "Invoice Payment"}
        </h2>

        {invoice?.splitBilling?.isSplit && (
          <div className="mb-6">
            {isParentInvoice ? (
              <p className="text-sm text-gray-600">
                This is a Parent invoice in a split bill. Child invoice ID is:{" "}
                {siblingInvoiceId}
              </p>
            ) : (
              <p className="text-sm text-gray-600">
                This is a Child invoice in a split bill. Parent invoice ID is:{" "}
                {siblingInvoiceId}
              </p>
            )}
          </div>
        )}

        {/* Two-row layout */}
        <div className="flex flex-col gap-6">
          {/* Row 1: Invoice Summary */}
          <div className="flex-1">
            {/* <InvoiceGenerator data={invoice} /> */}
          </div>

          {/* Row 2: Payment Details and Client Information */}
          <div className="flex flex-col gap-6">
            <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
              {/* Client Information */}
              <div className="mb-6 col-span-1">
                <h3 className="text-sm font-medium leading-6 text-gray-900">
                  Client: {invoice?.client?.firstName}{" "}
                  {invoice?.client?.lastName}
                </h3>
                <p className="text-sm text-gray-600">
                  Email:{" "}
                  {invoice?.client?.email ? invoice?.client?.email : "N/A"}
                </p>
                {invoice?.client?.phoneNumbers?.length > 0 && (
                  <p className="text-sm text-gray-600">
                    Phone: {invoice?.client?.phoneNumbers[0]?.number}
                  </p>
                )}
              </div>

              {/* Invoice Details */}
              <div className="mb-6 col-span-1">
                <h4 className="text-sm font-medium leading-6 text-gray-900">
                  Invoice Details
                </h4>
                <p className="text-sm text-gray-600">
                  Amount: ${invoice?.amount?.toFixed(2)}
                </p>
                <p className="text-sm text-gray-600">
                  Status:{" "}
                  {invoice?.status
                    ? invoice?.status.charAt(0).toUpperCase() +
                      invoice?.status.slice(1)
                    : "N/A"}
                </p>
                <p className="text-sm text-gray-600">
                  Service:{" "}
                  {invoice?.services
                    ?.map((service) => service?.description)
                    ?.join(", ")}
                </p>
              </div>

              {/* Payment Method */}
              <div className="col-span-1">
                <label
                  htmlFor="payment-method"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Payment Method
                </label>
                <div className="mt-2">
                  <select
                    id="payment-method"
                    name="payment-method"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option value="">Select Payment Method</option>
                    <option value="mastercard-in-person">
                      Mastercard (In-Person)
                    </option>
                    <option value="mastercard-online">
                      Mastercard (Online)
                    </option>
                    <option value="mastercard-send-link">
                      Mastercard (Send Payment Link)
                    </option>
                    <option value="visa-in-person">Visa (In-Person)</option>
                    <option value="visa-online">Visa (Online)</option>
                    <option value="visa-send-link">
                      Visa (Send Payment Link)
                    </option>
                    <option value="debit-in-person">Debit (In-Person)</option>
                    <option value="email-money-transfer">
                      Email Money Transfer
                    </option>
                    <option value="direct-deposit">Direct Deposit</option>
                  </select>
                </div>
              </div>

              {/* Payment Amount */}
              <div className="col-span-1">
                <label
                  htmlFor="payment-amount"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Payment Amount
                </label>
                <div className="mt-2">
                  <input
                    id="payment-amount"
                    name="payment-amount"
                    type="number"
                    step="0.01"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Payment Date Input */}
              <div className="col-span-1">
                <label
                  htmlFor="payment-date"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Payment Date
                </label>
                <div className="mt-2">
                  <input
                    id="payment-date"
                    type="date"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Error message */}
        {error && <div className="text-sm text-red-600 mt-4">{error}</div>}

        {/* Action Buttons */}
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onClose}
            className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSavePayment}
            disabled={!paymentMethod || paymentAmount <= 0}
            className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
              !paymentMethod || paymentAmount <= 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            }`}>
            {payment ? "Update Payment" : "Mark payment as received"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicePaymentModal;
