import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import WideSidePanel from "../../applicationUi/components/WideSidePanel";
import ClinicianDashboardComponent from "./clinician/ClinicianDashboardComponent";
import LockScreen from "../../auth/lockScreen";
import QuickAddModal from "../../modals/QuickAddModals";
import ClinicScheduleModal from "../schedulePage/ClinicScheduleModal";
import AdminDashboardComponent from "./admin/AdminDashboardComponent";
import OwnerDashboardComponent from "./owner/OwnerDashboardComponent";
import { fetchUsers } from "../../store/thunks/userThunk";

function DashboardPage({ user }) {
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isWideSidePanelOpen, setWideSidePanelOpen] = useState(false);
    const [isScreenLocked, setIsScreenLocked] = useState(false);
    const [activeModal, setActiveModal] = useState(null);
    const [rescheduleData, setRescheduleData] = useState(null);
    const [selectedDashboard, setSelectedDashboard] = useState("clinician");
    const users = useSelector((state) => state.users.users || []);
    const dispatch = useDispatch();

    const selectedUser = useSelector((state) => state.auth.user);

    console.log(selectedUser.role);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const handleAppointmentClick = (appointment) => {
        setSelectedAppointment(appointment);
        setWideSidePanelOpen(true);
    };

    const handleClientClick = (client) => {
        setSelectedClient(client);
        setWideSidePanelOpen(true);
    };

    const handleCloseWideSidePanel = () => {
        setWideSidePanelOpen(false);
    };

    const handleReschedule = (appointment) => {
        setRescheduleData(appointment);
        setWideSidePanelOpen(false);
        setActiveModal("schedule");
    };

    const handleCloseQuickAddModal = () => {
        setActiveModal(null);
    };

    const renderDashboard = useMemo(() => {
        const userRoles = selectedUser?.role?.map(role => role.toLowerCase()) || [];
        let dashboardComponents = [];

        if (userRoles.includes("clinician")) {
            dashboardComponents.push(
                <ClinicianDashboardComponent
                    key="clinician-dashboard"
                    handleAppointmentClick={handleAppointmentClick}
                    handleClientClick={handleClientClick}
                    handleReschedule={handleReschedule}
                    clinician={selectedUser}
                />
            );
        }

        if (userRoles.includes("admin")) {
            dashboardComponents.push(
                <AdminDashboardComponent
                    key="admin-dashboard"
                    handleAppointmentClick={handleAppointmentClick}
                    handleClientClick={handleClientClick}
                    handleReschedule={handleReschedule}
                    clinician={selectedUser}
                />
            );
        }

        if (userRoles.includes("owner")) {
            dashboardComponents.push(
                <OwnerDashboardComponent
                    key="owner-dashboard"
                    handleAppointmentClick={handleAppointmentClick}
                    handleClientClick={handleClientClick}
                    handleReschedule={handleReschedule}
                    clinician={selectedUser}
                />
            );
        }

        if (dashboardComponents.length === 0) {
            return <div>No recognized roles</div>;
        }

        return (
            <>
                {dashboardComponents}
            </>
        );
    }, [selectedUser, handleAppointmentClick, handleClientClick, handleReschedule]);

    return (
        <>
            {isScreenLocked && <LockScreen />}
            {/* <div>
                <label>Select User:</label>
                <select
                    value={selectedUser?._id || ""}
                    onChange={(e) =>
                        setSelectedUser(
                            users.find((u) => u._id === e.target.value) || {}
                        )
                    }
                >
                    {Array.isArray(users) &&
                        users.map((user) => (
                            <option key={user._id} value={user._id}>
                                {user.firstName} {user.lastName}
                            </option>
                        ))}
                </select>

                <label>Select Dashboard:</label>
                <select
                    value={selectedDashboard}
                    onChange={(e) => setSelectedDashboard(e.target.value)}
                >
                    <option value="clinician">Clinician</option>
                    <option value="admin">Admin</option>
                    <option value="owner">Owner</option>
                </select>
            </div> */}

            {renderDashboard}

            {selectedAppointment && (
                <WideSidePanel
                    title={"Appointment Summary"}
                    open={isWideSidePanelOpen}
                    onClose={handleCloseWideSidePanel}
                    appointment={selectedAppointment}
                    onAppointmentClick={handleAppointmentClick}
                    onReschedule={handleReschedule}
                />
            )}
            <QuickAddModal
                activeModal={activeModal}
                onClose={handleCloseQuickAddModal}
            />
            {activeModal === "schedule" && (
                <ClinicScheduleModal
                    setModalOpen={(state) => {
                        setActiveModal(state ? "schedule" : null);
                    }}
                    selectedClient={rescheduleData?.client}
                    selectedService={rescheduleData?.service}
                    selectedProduct={rescheduleData?.product}
                    selectedClinician={rescheduleData?.clinician}
                    rescheduleData={rescheduleData}
                />
            )}
        </>
    );
}

export default DashboardPage;
