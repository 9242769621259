import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClients,
  deleteClient,
  fetchClientById,
} from "../../store/thunks/clientsThunk";
import EditClientModal from "./EditClientModal";
import ConfirmDeleteDialog from "../../dialogs/ConfirmDeleteDialog";
import ClientOverview from "./ClientOverview";
import Table from "../../applicationUi/components/Table";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { format, differenceInYears } from "date-fns";
import { logInfo, logError } from "../../utils/logger";
import { clearSelectedClinicians } from "../../store/slices/cliniciansSlice";
import { clearSelectedClient } from "../../store/slices/clientsSlice";

export default function ClientsPage({ clickedItem }) {
  const dispatch = useDispatch();
  const {
    clients = [],
    pagination = { currentPage: 1, pageSize: 10 },
    status: clientStatus,
    error: clientError,
  } = useSelector((state) => state.clients);

  const [selectedClient, setSelectedClient] = useState(null);
  const [isEditClientModalOpen, setIsEditClientModalOpen] = useState(false);
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  useEffect(() => {
    if (clickedItem && clickedItem._id) {
      logInfo("Fetching specific client", { clientId: clickedItem._id });
      dispatch(fetchClientById(clickedItem._id))
        .unwrap()
        .then((client) => {
          logInfo("Specific client fetched successfully", {
            clientId: client._id,
          });
          setSelectedClient(client);
        })
        .catch((error) => {
          logError("Failed to fetch specific client", {
            error,
            clientId: clickedItem._id,
          });
          console.error("Failed to fetch specific client:", error);
        });
    } else {
      logInfo("Fetching clients", {
        page: pagination.currentPage,
        limit: pagination.pageSize,
      });
      dispatch(
        fetchClients({
          page: pagination.currentPage,
          limit: pagination.pageSize,
        })
      )
        .unwrap()
        .then(() => {
          logInfo("Clients fetched successfully", {
            clientCount: clients.length,
          });
        })
        .catch((error) => {
          logError("Failed to fetch clients", { error });
          console.error("Failed to fetch clients:", error);
        });
    }
  }, [
    dispatch,
    pagination.currentPage,
    pagination.pageSize,
    clickedItem,
    clients.length,
  ]);

  const handleRowClick = (clientId) => {
    logInfo("Client row clicked", { clientId });
    const client = clients.find((client) => client._id === clientId);
    setSelectedClient(client);
  };

  const handleSearchClick = (client) => {
    logInfo("Client selected from search", { clientId: client._id });
    setSelectedClient(client);
  };

  const handleBackToClients = () => {
    logInfo("Navigating back to client list");
    setSelectedClient(null);
    dispatch(clearSelectedClient());
    dispatch(clearSelectedClinicians());
  };

  const formattedClients =
    clients?.map((client) => {
      const dateOfBirth = client.dateOfBirth
        ? new Date(client.dateOfBirth)
        : null;
      const formattedDateOfBirth = dateOfBirth
        ? format(dateOfBirth, "MMMM d, yyyy")
        : "N/A";
      const age = dateOfBirth
        ? differenceInYears(new Date(), dateOfBirth)
        : "N/A";

      const formattedPhoneNumbers = client.phoneNumbers
        ? client.phoneNumbers.map((phone) => `${phone.number}`).join(", ")
        : "N/A";

      return {
        ...client,
        dateOfBirth: formattedDateOfBirth,
        age,
        formattedPhoneNumbers,
      };
    }) || [];

  logInfo("Rendered ClientsPage", {
    clientCount: clients.length,
    selectedClient: selectedClient ? selectedClient._id : null,
    clientStatus,
    paginationInfo: pagination,
  });

  return (
    <div>
      {selectedClient ? (
        <div className="p-6">
          <ClientOverview
            selectedClient={selectedClient}
            onClose={handleBackToClients}
          />
        </div>
      ) : (
        <>
          <div className="m-8">
            <div className="text-base font-semibold leading-6 text-gray-900">
              Search for clients by first name, last name, email or phone
              number:
            </div>
            <SearchDropdown
              prefix=":client:"
              displayFields={["firstName", "lastName", "email", "phoneNumbers"]}
              onSelection={(client) => handleSearchClick(client)}
              placeholder={"Type to start searching..."}
            />
          </div>

          {clientStatus === "loading" && <p>Loading clients...</p>}
          {clientStatus === "failed" && (
            <p>
              Error loading clients:{" "}
              {typeof clientError === "object"
                ? JSON.stringify(clientError)
                : clientError}
            </p>
          )}
          {clientStatus === "succeeded" && clients.length === 0 && (
            <p>No clients available</p>
          )}
          {clientStatus === "succeeded" && clients.length > 0 && (
            <Table
              columns={[
                { header: "First Name", accessor: "firstName" },
                { header: "Last Name", accessor: "lastName" },
                { header: "Date of Birth", accessor: "dateOfBirth" },
                { header: "Age", accessor: "age" },
                { header: "Email", accessor: "email" },
                { header: "Phone Numbers", accessor: "formattedPhoneNumbers" },
              ]}
              data={formattedClients}
              onRowClick={handleRowClick}
              actions={[
                {
                  label: "Add Client",
                  onClick: () => {
                    logInfo("Add Client button clicked");
                    setIsEditClientModalOpen(true);
                  },
                },
              ]}
              title="Clients"
              description="A list of all clients. Use pagination to view more."
              showPagination={pagination.totalPages > 1}
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              onPageChange={(page) => {
                logInfo("Pagination changed", { newPage: page });
                dispatch(
                  fetchClients({
                    page,
                    limit: pagination.pageSize,
                  })
                );
              }}
            />
          )}
        </>
      )}

      {isEditClientModalOpen && selectedClient && (
        <EditClientModal
          client={selectedClient}
          onClose={() => {
            logInfo("Edit Client Modal closed");
            setIsEditClientModalOpen(false);
          }}
        />
      )}

      {isDeleteClientModalOpen && (
        <ConfirmDeleteDialog
          open={isDeleteClientModalOpen}
          setOpen={setIsDeleteClientModalOpen}
          onDelete={() => {
            logInfo("Deleting client", { clientId: clientToDelete });
            dispatch(deleteClient(clientToDelete));
          }}
        />
      )}
    </div>
  );
}
