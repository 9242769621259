import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { modifyUser } from "../../../store/thunks/userThunk";
import { ALL_ROLES } from "../../../constants/roles";

const UserHrInformation = ({ user }) => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            employmentStatus: user.employmentStatus || "",
            activeStatus: user.userStatus || "active",
            hireDate: user.startDate
                ? new Date(user.startDate).toISOString().split("T")[0]
                : "",
            terminationDate: user.terminationDate
                ? new Date(user.terminationDate).toISOString().split("T")[0]
                : "",
            jobTitle: user.jobTitle || "",
            department: user.department || "",
            role: Array.isArray(user.role) ? user.role : [user.role],
            specialization: user.specialization || [],
            qualifications: user.qualifications || "",
        },
        validationSchema: Yup.object({
            employmentStatus: Yup.string().required("Required"),
            activeStatus: Yup.string().required("Required"),
            hireDate: Yup.date().required("Required"),
            terminationDate: Yup.date().nullable(),
            jobTitle: Yup.string().required("Required"),
            department: Yup.string().required("Required"),
            role: Yup.array().min(1, "At least one role must be selected"),
            specialization: Yup.array().when('role', {
                is: (role) => role && role.includes("clinician"),
                then: () => Yup.array().min(1, "At least one specialization must be selected"),
                otherwise: () => Yup.array().notRequired(),
            }),
            qualifications: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            const updatedValues = {
                ...values,
                userStatus: values.activeStatus,
                startDate: values.hireDate,
                specialization: values.specialization, // Ensure specialization is included
            };
            console.log("Submitting form with values:", updatedValues);
            dispatch(modifyUser({ userId: user._id, userData: updatedValues }))
                .then((response) => {
                    console.log("User modified successfully:", response);
                })
                .catch((error) => {
                    console.error("Error modifying user:", error);
                });
        },
    });

    const specializations = ["SLP", "CDA"];

    return (
        <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label
                        htmlFor="employmentStatus"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Employment Status
                    </label>
                    <div className="mt-1">
                        <select
                            id="employmentStatus"
                            name="employmentStatus"
                            {...formik.getFieldProps("employmentStatus")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                            <option value="">Select status</option>
                            <option value="Full-time">Full-time</option>
                            <option value="Part-time">Part-time</option>
                            <option value="Contract">Contract</option>
                            <option value="Temporary">Temporary</option>
                        </select>
                    </div>
                    {formik.touched.employmentStatus &&
                    formik.errors.employmentStatus ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.employmentStatus}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="activeStatus"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Active Status
                    </label>
                    <div className="mt-1">
                        <select
                            id="activeStatus"
                            name="activeStatus"
                            {...formik.getFieldProps("activeStatus")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive (User will not be able to login)</option>
                        </select>
                    </div>
                    {formik.touched.activeStatus && formik.errors.activeStatus ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.activeStatus}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="hireDate"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Hire Date
                    </label>
                    <div className="mt-1">
                        <input
                            type="date"
                            name="hireDate"
                            id="hireDate"
                            {...formik.getFieldProps("hireDate")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {formik.touched.hireDate && formik.errors.hireDate ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.hireDate}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="terminationDate"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Termination Date
                    </label>
                    <div className="mt-1">
                        <input
                            type="date"
                            name="terminationDate"
                            id="terminationDate"
                            {...formik.getFieldProps("terminationDate")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {formik.touched.terminationDate && formik.errors.terminationDate ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.terminationDate}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="jobTitle"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Job Title
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="jobTitle"
                            id="jobTitle"
                            {...formik.getFieldProps("jobTitle")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {formik.touched.jobTitle && formik.errors.jobTitle ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.jobTitle}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="department"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Department
                    </label>
                    <div className="mt-1">
                        <select
                            id="department"
                            name="department"
                            {...formik.getFieldProps("department")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                            <option value="">Select department</option>
                            <option value="Speech">Speech</option>
                            <option value="Audiology">Audiology</option>
                            <option value="Corporate">Corporate</option>
                        </select>
                    </div>
                    {formik.touched.department && formik.errors.department ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.department}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-6">
                    <label
                        htmlFor="qualifications"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Qualifications
                    </label>
                    <div className="mt-1">
                        <textarea
                            id="qualifications"
                            name="qualifications"
                            rows="3"
                            {...formik.getFieldProps("qualifications")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {formik.touched.qualifications && formik.errors.qualifications ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.qualifications}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-6">
                    <label className="block text-sm font-medium text-gray-700">
                        Role and Specialization
                    </label>
                    <div className="mt-2 flex space-x-4">
                        <div className="w-1/2">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Role</label>
                            <div className="space-y-2">
                                {ALL_ROLES.map((role) => (
                                    <div key={role} className="flex items-center">
                                        <input
                                            id={`role-${role}`}
                                            name="role"
                                            type="checkbox"
                                            value={role}
                                            checked={formik.values.role.includes(role)}
                                            onChange={formik.handleChange}
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                        />
                                        <label htmlFor={`role-${role}`} className="ml-3 text-sm text-gray-700">
                                            {role.charAt(0).toUpperCase() + role.slice(1)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            {formik.touched.role && formik.errors.role ? (
                                <div className="text-red-500 text-sm mt-1">
                                    {formik.errors.role}
                                </div>
                            ) : null}
                        </div>
                        {formik.values.role.includes("clinician") && (
                            <div className="w-1/2">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Specialization</label>
                                <div className="space-y-2">
                                    {specializations.map((spec) => (
                                        <div key={spec} className="flex items-center">
                                            <input
                                                id={`specialization-${spec}`}
                                                name="specialization"
                                                type="checkbox"
                                                value={spec}
                                                checked={formik.values.specialization.includes(spec)}
                                                onChange={formik.handleChange}
                                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor={`specialization-${spec}`} className="ml-3 text-sm text-gray-700">
                                                {spec}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {formik.touched.specialization && formik.errors.specialization ? (
                                    <div className="text-red-500 text-sm mt-1">
                                        {formik.errors.specialization}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
};

export default UserHrInformation;
