import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  setMeetings,
  addMeeting,
  updateMeeting,
  deleteMeeting,
  setCurrentMeeting,
  setLoading,
  setError,
} from "../slices/meetingSlice";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Fetch all meetings
export const fetchMeetings = createAsyncThunk(
  "meetings/fetchMeetings",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/meetings`, config);
      dispatch(setMeetings(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Fetch meetings for the current week
export const fetchMeetingsForCurrentWeek = createAsyncThunk(
  "meetings/fetchMeetingsForCurrentWeek",
  async ({ startDate, endDate }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/meetings/week`, {
        ...config,
        params: { startDate, endDate },
      });
      dispatch(setMeetings(response.data));
      console.log("fetched meetings for current week", response.data);
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// Thunk to check for meeting conflicts
export const checkMeetingConflicts = createAsyncThunk(
  "meetings/checkConflicts",
  async (meetingData, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/meetings/conflict-check`,
        meetingData,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a new meeting, with validation for overlapping meetings
export const createMeeting = createAsyncThunk(
  "meetings/createMeeting",
  async (meetingData, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/meetings`,
        meetingData,
        config
      );

      // If there's a conflict warning (status 409), handle it in the frontend
      if (response.status === 409) {
        return {
          warning: true,
          message: response.data.message,
          conflicts: response.data.conflicts,
        };
      }

      dispatch(addMeeting(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Update an existing meeting
export const updateMeetingThunk = createAsyncThunk(
  "meetings/updateMeeting",
  async ({ id, updatedData }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.put(
        `${baseURL}/api/meetings/${id}`, // Ensure the ID is correctly passed here
        updatedData,
        config
      );
      dispatch(updateMeeting(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Delete a meeting
export const deleteMeetingThunk = createAsyncThunk(
  "meetings/deleteMeeting",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/meetings/${id}`, config);
      dispatch(deleteMeeting(id));
      return id;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Fetch a single meeting by ID
export const fetchMeetingById = createAsyncThunk(
  "meetings/fetchMeetingById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/meetings/${id}`, config);
      dispatch(setCurrentMeeting(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);
