import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvoice,
  fetchInvoicesByClientId,
  createSplitInvoice,
} from "../../store/thunks/invoiceThunk";
import { updateAppointmentStatus } from "../../store/thunks/appointmentsThunk";
import InvoiceGenerator from "./InvoiceGenerator";
import InvoiceList from "./InvoiceList";
import InvoicePaymentModal from "../../modals/InvoicePaymentModal";
import SearchDropdown from "./SearchDropdown";
import { XMarkIcon } from "@heroicons/react/24/solid"; // Import Heroicon

export default function InvoiceModal({ appointment, onClose }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [notes, setNotes] = useState("");
  const [childNotes, setChildNotes] = useState("");
  const [parentNotes, setParentNotes] = useState("");
  const [viewInvoice, setViewInvoice] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [invoices, setInvoices] = useState([]); // This is for the fetchInvoicesByClientId thunk
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [viewMode, setViewMode] = useState("create");
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [customPrice, setCustomPrice] = useState(null);
  const [customChildPrice, setCustomChildPrice] = useState(null);
  const [customParentPrice, setCustomParentPrice] = useState(null);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedParentService, setSelectedParentService] = useState(null);
  const [childInvoice, setChildInvoice] = useState(null);
  const [parentInvoice, setParentInvoice] = useState(null);

  const [responseMessage, setResponseMessage] = useState("");
  const [responseInvoiceId, setResponseInvoiceId] = useState("");
  const [responseQboInvoiceId, setResponseQboInvoiceId] = useState("");

  useEffect(() => {
    const fetchInvoices = async () => {
      if (appointment?.client?._id) {
        try {
          const response = await dispatch(
            fetchInvoicesByClientId(appointment.client._id)
          );
          if (response.meta.requestStatus === "fulfilled") {
            setInvoices(response.payload);
          } else {
            console.error("Error fetching invoices:", response.error);
          }
        } catch (error) {
          console.error("Error fetching invoices:", error);
        }
      }
    };

    fetchInvoices();
  }, [dispatch, appointment]);

  const fetchClientInvoices = async (clientId) => {
    try {
      const response = await dispatch(fetchInvoicesByClientId(clientId));
      setInvoices(response.payload);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const refetchInvoices = () => {
    if (appointment?.client?._id) {
      fetchClientInvoices(appointment.client._id);
    }
  };

  const handleSubmit = async () => {
    const serviceData = {
      ...appointment.service,
      price: customPrice || appointment.service.price, // Override if customPrice is set
    };

    const invoiceData = {
      appointmentId: appointment._id,
      clinic: appointment.clinic,
      clinician: appointment.clinician,
      client: appointment.client,
      service: serviceData,
      notes,
    };

    try {
      const response = await dispatch(createInvoice(invoiceData));

      if (response.meta.requestStatus === "fulfilled") {
        console.log("Invoice created successfully:", response.payload);
        setInvoice(response.payload);
        setResponseMessage("Invoice created successfully");
        setResponseInvoiceId(response.payload.invoiceId);
        setResponseQboInvoiceId(response.payload.qboInvoiceId);
        setViewInvoice(true);
        setIsSuccessful(true);

        // Dispatch updateAppointmentStatus if invoice creation is successful
        dispatch(
          updateAppointmentStatus({
            appointmentId: appointment._id,
            status: "Invoiced",
            comments: `Status changed to Invoiced`,
            userId: user._id,
          })
        )
          .then((statusResponse) => {
            if (statusResponse.meta.requestStatus === "fulfilled") {
              console.log(`Appointment status changed to Invoiced`);
              // refetchAppointments(appointment.clinician._id);
            }
          })
          .catch((error) => {
            console.error(`Failed to change appointment status:`, error);
          });

        dispatch(fetchInvoicesByClientId(appointment.client._id));
      } else if (
        response.payload &&
        response.payload.warning === "Invoice already exists"
      ) {
        setResponseMessage(
          `Invoice already exists with ID: ${response.payload.invoiceId}`
        );
        setResponseInvoiceId(response.payload.invoiceId);
      } else {
        console.error("Error creating invoice:", response.error);
        setResponseMessage("Error creating invoice");
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      setResponseMessage("Error creating invoice");
    }
  };

  const handleSplitInvoiceSubmit = async () => {
    const childInvoiceData = {
      appointmentId: appointment._id,
      clinic: appointment.clinic,
      clinician: appointment.clinician,
      client: selectedClient || appointment.client,
      service: {
        ...(selectedService || appointment.service),
        price:
          customChildPrice ||
          (selectedService ? selectedService.price : appointment.service.price), // Override price if custom price is provided
      },
      notes: childNotes,
      isSplit: true,
    };

    const parentInvoiceData = {
      appointmentId: appointment._id,
      clinic: appointment.clinic,
      clinician: appointment.clinician,
      client: appointment.client,
      parentInfo: selectedParent,
      service: {
        ...(selectedParentService || appointment.service),
        price:
          customParentPrice ||
          (selectedParentService
            ? selectedParentService.price
            : appointment.service.price), // Override price if custom price is provided
      },
      notes: parentNotes,
      isSplit: true,
    };

    try {
      const response = await dispatch(
        createSplitInvoice({ childInvoiceData, parentInvoiceData })
      );

      if (response.meta.requestStatus === "fulfilled") {
        console.log("Split Invoices created successfully:", response.payload);
        setResponseMessage("Split Invoices created successfully");
        setResponseInvoiceId(
          `Child Invoice ID: ${response.payload.invoices.childInvoice.invoiceId}, ` +
            `Parent Invoice ID: ${response.payload.invoices.parentInvoice.invoiceId}`
        );
        setChildInvoice(response.payload.invoices.childInvoice);
        setParentInvoice(response.payload.invoices.parentInvoice);
        setViewInvoice(true);

        // Dispatch updateAppointmentStatus if split invoice creation is successful
        dispatch(
          updateAppointmentStatus({
            appointmentId: appointment._id,
            status: "Invoiced",
            comments: `Status changed to Invoiced`,
            userId: user._id,
          })
        )
          .then((statusResponse) => {
            if (statusResponse.meta.requestStatus === "fulfilled") {
              console.log(`Appointment status changed to Invoiced`);
              // refetchAppointments(appointment.clinician._id);
            }
          })
          .catch((error) => {
            console.error(`Failed to change appointment status:`, error);
          });

        dispatch(fetchInvoicesByClientId(appointment.client._id))
          .then((refetchResponse) => {
            if (refetchResponse.meta.requestStatus === "fulfilled") {
              console.log(
                "Client invoices refetched successfully:",
                refetchResponse.payload
              );
              setInvoices(refetchResponse.payload); // Update invoices state with fetched data
            } else {
              console.error(
                "Error refetching client invoices:",
                refetchResponse.error
              );
            }
          })
          .catch((refetchError) => {
            console.error("Error refetching client invoices:", refetchError);
          });
      } else if (
        response.payload &&
        response.payload.error === "Invoice already exists"
      ) {
        setResponseMessage(
          `Invoice already exists with ID: ${response.payload.invoiceId}`
        );
        setResponseInvoiceId(response.payload.invoiceId);
      } else {
        console.error("Split Invoice creation failed:", response.error);
        setResponseMessage("Error creating split invoices");
      }
    } catch (error) {
      console.error("Error creating Split Invoices:", error);
      setResponseMessage("Error creating split invoices");
    }
  };

  const toggleView = (mode) => {
    setViewMode(mode);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Invoices
            </h2>

            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none">
              <XMarkIcon className="h-6 w-6" />
            </button>

            {/* Tabs to switch views */}
            <button className="inline-flex items-center mt-2 rounded-tl-md rounded-tr-md focus:outline-none">
              <div
                onClick={() => toggleView("create")}
                className={`${
                  viewMode === "create"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }  px-2.5 py-1.5 mt-2 text-sm font-semibold rounded-tl-md rounded-tr-md cursor-pointer`}>
                Create Invoice
              </div>

              <div
                onClick={() => toggleView("createsplit")}
                className={`${
                  viewMode === "createsplit"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }  px-2.5 py-1.5 mt-2 text-sm font-semibold rounded-tl-md rounded-tr-md cursor-pointer`}>
                Create Split Invoice
              </div>

              <div
                onClick={() => toggleView("viewThis")}
                className={`${
                  viewMode === "viewThis"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }  px-2.5 py-1.5 mt-2 text-sm font-semibold rounded-tl-md rounded-tr-md cursor-pointer`}>
                View This Invoice
              </div>

              <div
                onClick={() => toggleView("viewAll")}
                className={`${
                  viewMode === "viewAll"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }  px-2.5 py-1.5 mt-2 text-sm font-semibold rounded-tl-md rounded-tr-md cursor-pointer`}>
                View All Invoices
              </div>
            </button>

            {viewMode === "create" ? (
              <div>
                <div className="space-y-8 border-t border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
                    {/* Client Section */}
                    <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Client
                      </h2>
                      <p>
                        {appointment.client.firstName.trim()}{" "}
                        {appointment.client.lastName}
                      </p>
                      <p>
                        {appointment.client.address.aptUnit
                          ? `${appointment.client.address.aptUnit}, `
                          : ""}
                        {appointment.client.address.streetNumber}{" "}
                        {appointment.client.address.streetName},{" "}
                        {appointment.client.address.city},{" "}
                        {appointment.client.address.province},{" "}
                        {appointment.client.address.postalCode},{" "}
                        {appointment.client.address.country}
                      </p>
                    </div>

                    {/* Service Section */}
                    <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Service
                          </h2>
                          <p>{appointment.service.description}</p>
                          <p>${appointment.service.price.toFixed(2)}</p>
                          <p>
                            Taxable:{" "}
                            {appointment.service.tax &&
                            appointment.service.tax.isTaxable
                              ? "Yes"
                              : "No"}
                          </p>
                          {appointment.service.tax?.isTaxable && (
                            <p>
                              Tax Rate: {appointment.service.tax.taxRate * 100}%
                            </p>
                          )}
                        </div>

                        {/* Custom Price Section */}
                        <div>
                          <label
                            htmlFor="customPrice"
                            className="block text-sm font-medium text-gray-900">
                            Custom Price (Optional)
                          </label>
                          <input
                            type="number"
                            name="customPrice"
                            id="customPrice"
                            step="0.01"
                            min="0"
                            value={customPrice || ""}
                            onChange={(e) =>
                              setCustomPrice(parseFloat(e.target.value) || "")
                            }
                            placeholder="Enter custom price"
                            className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Clinician Section */}
                    <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Clinician
                      </h2>
                      <p>
                        {appointment.clinician.firstName}{" "}
                        {appointment.clinician.lastName}
                      </p>
                      <p>{appointment.clinician.qualifications}</p>
                      <p>{appointment.clinician.jobTitle}</p>
                    </div>

                    {/* Clinic Section */}
                    <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Clinic
                      </h2>
                      <p>{appointment.clinic.name}</p>
                      <p>
                        {appointment.clinic.address.street},{" "}
                        {appointment.clinic.address.city},{" "}
                        {appointment.clinic.address.province},{" "}
                        {appointment.clinic.address.postalCode},{" "}
                        {appointment.clinic.address.country}
                      </p>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <div>
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Invoice Notes
                      </h2>
                    </div>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <textarea
                        id="notes"
                        name="notes"
                        placeholder="Type any invoice related notes. Leave empty if no notes required."
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={notes}
                        onChange={handleNotesChange}
                        // readOnly={isSuccessful}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between pt-8">
                  <p className="block text-sm font-small leading-6 text-gray-900">
                    After generating the invoice, you will be able to view,
                    download, print, or edit.
                  </p>
                  <button
                    onClick={handleSubmit}
                    className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Generate Invoice
                  </button>
                </div>

                {responseMessage && (
                  <div
                    className={`mt-4 p-4 rounded-md ${
                      responseMessage.includes("Invoice already exists")
                        ? "bg-orange-100"
                        : "bg-green-100"
                    }`}>
                    <p
                      className={`text-sm font-semibold ${
                        responseMessage.includes("Invoice already exists")
                          ? "text-orange-800"
                          : "text-green-800"
                      }`}>
                      {responseMessage}
                    </p>
                    {responseInvoiceId && (
                      <p className="text-sm text-gray-700">
                        Invoice ID: {responseInvoiceId}
                      </p>
                    )}
                    {responseQboInvoiceId && (
                      <p className="text-sm text-gray-700">
                        QBO Invoice ID: {responseQboInvoiceId}
                      </p>
                    )}
                  </div>
                )}

                <div>
                  {viewInvoice && invoice && (
                    <div className="pt-4">
                      <InvoiceGenerator data={invoice} initialViewMode={true} />
                    </div>
                  )}
                </div>
              </div>
            ) : viewMode === "createsplit" ? (
              <div className="space-y-8  pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                {/* Clinician and Clinic */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
                  <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Clinician
                    </h2>
                    <p>
                      {appointment.clinician.firstName}{" "}
                      {appointment.clinician.lastName}
                    </p>
                    <p>{appointment.clinician.qualifications}</p>
                    <p>{appointment.clinician.jobTitle}</p>
                  </div>

                  <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Clinic
                    </h2>
                    <p>{appointment.clinic.name}</p>
                    <p>
                      {appointment.clinic.address.street},{" "}
                      {appointment.clinic.address.city},{" "}
                      {appointment.clinic.address.province},{" "}
                      {appointment.clinic.address.postalCode},{" "}
                      {appointment.clinic.address.country}
                    </p>
                  </div>
                </div>

                {/* Child Invoice Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
                  <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Child Invoice
                    </h2>
                    <>
                      <p>
                        {selectedClient
                          ? `${selectedClient.firstName.trim()} ${
                              selectedClient.lastName
                            }`
                          : `${appointment.client.firstName.trim()} ${
                              appointment.client.lastName
                            }`}
                      </p>
                      <p>
                        {selectedClient
                          ? `${
                              selectedClient.address.aptUnit
                                ? `${selectedClient.address.aptUnit}, `
                                : ""
                            }${selectedClient.address.streetNumber} ${
                              selectedClient.address.streetName
                            }, ${selectedClient.address.city}, ${
                              selectedClient.address.province
                            }, ${selectedClient.address.postalCode}, ${
                              selectedClient.address.country
                            }`
                          : `${
                              appointment.client.address.aptUnit
                                ? `${appointment.client.address.aptUnit}, `
                                : ""
                            }${appointment.client.address.streetNumber} ${
                              appointment.client.address.streetName
                            }, ${appointment.client.address.city}, ${
                              appointment.client.address.province
                            }, ${appointment.client.address.postalCode}, ${
                              appointment.client.address.country
                            }`}
                      </p>
                    </>
                  </div>

                  <SearchDropdown
                    prefix=":client:"
                    displayFields={[
                      "firstName",
                      "lastName",
                      "email",
                      "phoneNumbers",
                    ]}
                    onSelection={(client) => setSelectedClient(client)}
                    placeholder={"Search for an existing client"}
                  />

                  {/* Service Section */}
                  <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Service
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <p>
                          {selectedService?.description ||
                            appointment?.service?.description ||
                            "N/A"}
                        </p>
                        <p>
                          $
                          {selectedService
                            ? selectedService.price.toFixed(2)
                            : appointment?.service?.price?.toFixed(2) || "N/A"}
                        </p>
                        <p>
                          Taxable:{" "}
                          {selectedService?.tax?.isTaxable ??
                          appointment?.service?.tax?.isTaxable
                            ? "Yes"
                            : "No"}
                        </p>
                        {selectedService?.tax?.isTaxable && (
                          <p>Tax Rate: {selectedService.tax.taxRate * 100}%</p>
                        )}
                        {!selectedService &&
                          appointment?.service?.tax?.isTaxable && (
                            <p>
                              Tax Rate: {appointment.service.tax.taxRate * 100}%
                            </p>
                          )}
                      </div>
                      {/* Custom Price for Child Invoice */}
                      <div className="mt-0">
                        <label
                          htmlFor="customChildPrice"
                          className="block text-sm font-medium text-gray-900">
                          Custom Price (Optional)
                        </label>
                        <input
                          type="number"
                          name="customChildPrice"
                          id="customChildPrice"
                          step="0.01"
                          min="0"
                          value={customChildPrice || ""}
                          onChange={(e) =>
                            setCustomChildPrice(
                              parseFloat(e.target.value) || ""
                            )
                          }
                          placeholder="Enter custom price"
                          className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <SearchDropdown
                    prefix=":service:"
                    displayFields={["serviceCode", "description", "price"]}
                    onSelection={(service) => setSelectedService(service)}
                    placeholder={"Search for a service by name or service code"}
                  />
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Invoice Notes for Child
                    </h2>
                  </div>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <textarea
                      id="childNotes"
                      name="childNotes"
                      placeholder="Type any invoice related notes for the child. Leave empty if no notes required."
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={childNotes}
                      onChange={(e) => setChildNotes(e.target.value)}
                    />
                  </div>
                </div>

                {/* Parent Invoice Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
                  <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Parent Invoice
                    </h2>
                    <>
                      <p>
                        {selectedParent
                          ? `${selectedParent.firstName.trim()} ${
                              selectedParent.lastName
                            }`
                          : ""}
                      </p>
                      <p>
                        {selectedParent
                          ? `${
                              selectedParent.address.aptUnit
                                ? `${selectedParent.address.aptUnit}, `
                                : ""
                            }${selectedParent.address.streetNumber} ${
                              selectedParent.address.streetName
                            }, ${selectedParent.address.city}, ${
                              selectedParent.address.province
                            }, ${selectedParent.address.postalCode}, ${
                              selectedParent.address.country
                            }`
                          : ""}
                      </p>
                    </>
                  </div>

                  <SearchDropdown
                    prefix=":client:"
                    displayFields={[
                      "firstName",
                      "lastName",
                      "email",
                      "phoneNumbers",
                    ]}
                    onSelection={(client) => setSelectedParent(client)}
                    placeholder={"Search for an existing client (or parent)"}
                  />

                  <div className="col-span-1 sm:col-span-1 text-sm leading-6 text-gray-900">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Service
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <p>
                          {selectedParentService
                            ? selectedParentService.description
                            : ""}
                        </p>
                        <p>
                          $
                          {selectedParentService
                            ? selectedParentService.price.toFixed(2)
                            : ""}
                        </p>
                        <p>
                          Taxable:{" "}
                          {selectedParentService
                            ? selectedParentService.tax.isTaxable
                              ? "Yes"
                              : "No"
                            : ""}
                        </p>
                        {selectedParentService &&
                          selectedParentService.tax.isTaxable && (
                            <p>
                              Tax Rate:{" "}
                              {selectedParentService.tax.taxRate * 100}%
                            </p>
                          )}
                      </div>
                      {/* Custom Price for Parent Invoice */}
                      <div className="mt-0">
                        <label
                          htmlFor="customParentPrice"
                          className="block text-sm font-medium text-gray-900">
                          Custom Price (Optional)
                        </label>
                        <input
                          type="number"
                          name="customParentPrice"
                          id="customParentPrice"
                          step="0.01"
                          min="0"
                          value={customParentPrice || ""}
                          onChange={(e) =>
                            setCustomParentPrice(
                              parseFloat(e.target.value) || ""
                            )
                          }
                          placeholder="Enter custom price"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <SearchDropdown
                    prefix=":service:"
                    displayFields={["serviceCode", "description", "price"]}
                    onSelection={(service) => setSelectedParentService(service)}
                    placeholder={"Search for a service by name or service code"}
                  />
                </div>

                {/* Invoice Notes Section */}
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Invoice Notes for Parent
                    </h2>
                  </div>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <textarea
                      id="parentNotes"
                      name="parentNotes"
                      placeholder="Type any invoice related notes for the parent. Leave empty if no notes required."
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={parentNotes}
                      onChange={(e) => setParentNotes(e.target.value)}
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-end space-x-4 py-4 mt-6">
                  <button
                    onClick={handleSplitInvoiceSubmit}
                    className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Create Split Invoices
                  </button>
                </div>

                {responseMessage && (
                  <div
                    className={`mt-4 p-4 rounded-md ${
                      responseMessage.includes("Invoice already exists")
                        ? "bg-orange-100"
                        : "bg-green-100"
                    }`}>
                    <p
                      className={`text-sm font-semibold ${
                        responseMessage.includes("Invoice already exists")
                          ? "text-orange-800"
                          : "text-green-800"
                      }`}>
                      {responseMessage}
                    </p>
                    {responseInvoiceId && (
                      <p className="text-sm text-gray-700">
                        Invoice ID: {responseInvoiceId}
                      </p>
                    )}
                  </div>
                )}

                {/* Invoice View */}
                <div>
                  {viewInvoice && childInvoice && parentInvoice && (
                    <div className="pt-4">
                      <InvoiceGenerator
                        data={childInvoice}
                        initialViewMode={true}
                      />
                      <InvoiceGenerator
                        data={parentInvoice}
                        initialViewMode={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : viewMode === "viewThis" ? (
              <InvoiceList
                invoices={invoices}
                refetchInvoices={refetchInvoices}
              />
            ) : viewMode === "viewAll" ? (
              <InvoiceList
                invoices={invoices}
                refetchInvoices={refetchInvoices}
              />
            ) : null}

            {/* {viewMode === "pay" && (
              <div>
                <InvoicePaymentModal invoice={invoice} />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
