import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meetings: [],
  loading: false,
  error: null,
  currentMeeting: null,
};

const meetingSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    setMeetings: (state, action) => {
      state.meetings = action.payload;
      state.loading = false;
      state.error = null;
    },
    addMeeting: (state, action) => {
      state.meetings.push(action.payload);
    },
    updateMeeting: (state, action) => {
      const index = state.meetings.findIndex(
        (meeting) => meeting._id === action.payload._id
      );
      if (index !== -1) {
        state.meetings[index] = action.payload;
      }
    },
    deleteMeeting: (state, action) => {
      state.meetings = state.meetings.filter(
        (meeting) => meeting._id !== action.payload
      );
    },
    setCurrentMeeting: (state, action) => {
      state.currentMeeting = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  setMeetings,
  addMeeting,
  updateMeeting,
  deleteMeeting,
  setCurrentMeeting,
  setLoading,
  setError,
} = meetingSlice.actions;

export default meetingSlice.reducer;
