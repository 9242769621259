import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../SoapForm.css";
import {
  saveSoapNote,
  updateSoapNote,
  fetchAppointmentsByClinicianId,
  signOffSoapNote,
} from "../../store/thunks/appointmentsThunk";
import SoapTemplate from "./SoapTemplate";

const TextAreaField = ({
  label,
  name,
  placeholder,
  value,
  readOnly,
  onChange,
}) => (
  <div className="mb-6">
    <h3 className="text-lg font-semibold leading-7 text-gray-900">{label}</h3>
    <p className="mt-1 text-sm leading-6 text-gray-600">{placeholder}</p>
    <textarea
      name={name}
      id={name}
      rows="4"
      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={value}
      readOnly={readOnly}
      onChange={onChange}
    />
  </div>
);

const SOAPFormModal = ({ onClose, appointment }) => {
  const dispatch = useDispatch();
  const selectedClinician = useSelector((state) => state.clinicians.selectedClinician);
  const user = useSelector((state) => state.auth.user);

  const isNewSoapNote = !appointment.soapNote || appointment.soapNote.length === 0;
  const [selectedVersion, setSelectedVersion] = useState(appointment?.soapNote?.length - 1 || 0);
  const [soapData, setSoapData] = useState({ subjective: "", objective: "", assessment: "", plan: "" });
  const [isSignedOff, setIsSignedOff] = useState(false);
  const [useNewTemplate, setUseNewTemplate] = useState(false);

  const refetchAppointments = (clinicianId) => {
    dispatch(fetchAppointmentsByClinicianId(clinicianId));
  };

  useEffect(() => {
    if (selectedClinician) {
      dispatch(fetchAppointmentsByClinicianId(selectedClinician._id));
    }
  }, [dispatch, selectedClinician]);

  useEffect(() => {
    if (appointment && appointment.soapNote && appointment.soapNote.length > 0) {
      const latestVersion = appointment.soapNote.length - 1;
      const currentSoapNote = appointment.soapNote[latestVersion];
      setSelectedVersion(latestVersion);
      setSoapData({
        subjective: currentSoapNote.subjective,
        objective: currentSoapNote.objective,
        assessment: currentSoapNote.assessment,
        plan: currentSoapNote.plan,
      });
      setIsSignedOff(!!currentSoapNote.signedOffBy);
    }
  }, [appointment]);

  const handleSave = async (event) => {
    event.preventDefault();
    const soapNote = { ...soapData, createdBy: user._id, format: useNewTemplate ? 'template' : 'freeform' };

    try {
      if (isNewSoapNote) {
        await dispatch(saveSoapNote([appointment, soapNote])).unwrap();
      } else {
        await dispatch(updateSoapNote([appointment, soapNote])).unwrap();
      }
      refetchAppointments(appointment.clinician._id);
      onClose();
    } catch (error) {
      console.error(`${isNewSoapNote ? 'Save' : 'Update'} failed:`, error);
    }
  };

  const handleSignOff = async () => {
    const signOffData = { appointment, user, soapNote: { ...soapData, format: useNewTemplate ? 'template' : 'freeform' } };
    try {
      await dispatch(signOffSoapNote(signOffData)).unwrap();
      setIsSignedOff(true);
      refetchAppointments(appointment.clinician._id);
      onClose();
    } catch (error) {
      console.error("Sign-off failed:", error);
    }
  };

  const handleToggleTemplate = () => setUseNewTemplate(!useNewTemplate);

  if (!appointment) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-70 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={onClose} />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-70 overflow-y-auto max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <form onSubmit={handleSave}>
          <div className="border-b border-gray-900/10 pb-6 mb-6">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900">SOAP Note</h2>
            <label className="mt-4 inline-flex items-center">
              <input
                type="checkbox"
                checked={useNewTemplate}
                onChange={handleToggleTemplate}
                className="form-checkbox h-5 w-5 text-indigo-600"
              />
              <span className="ml-2 text-gray-700">Use New SOAP Template</span>
            </label>
          </div>

          {useNewTemplate ? (
            <SoapTemplate 
              appointmentData={appointment} 
              soapData={soapData} 
              onChange={setSoapData} 
              readOnly={isSignedOff} 
            />
          ) : (
            <>
              <TextAreaField
                label="Subjective"
                name="subjective"
                placeholder="Patient's report of their condition."
                value={soapData.subjective}
                readOnly={isSignedOff}
                onChange={(e) => setSoapData({ ...soapData, subjective: e.target.value })}
              />
              <TextAreaField
                label="Objective"
                name="objective"
                placeholder="Observable, measurable findings."
                value={soapData.objective}
                readOnly={isSignedOff}
                onChange={(e) => setSoapData({ ...soapData, objective: e.target.value })}
              />
              <TextAreaField
                label="Assessment"
                name="assessment"
                placeholder="Diagnosis and analysis."
                value={soapData.assessment}
                readOnly={isSignedOff}
                onChange={(e) => setSoapData({ ...soapData, assessment: e.target.value })}
              />
              <TextAreaField
                label="Plan"
                name="plan"
                placeholder="Treatment plan and next steps."
                value={soapData.plan}
                readOnly={isSignedOff}
                onChange={(e) => setSoapData({ ...soapData, plan: e.target.value })}
              />
            </>
          )}

          <div className="mt-6 flex items-center justify-between gap-x-6">
            <button
              type="button"
              onClick={handleSignOff}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Approve Note
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isNewSoapNote ? "Save" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SOAPFormModal;
