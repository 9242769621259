import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchClinics } from "../../store/thunks/clinicThunk";
import Table from "../../applicationUi/components/Table";
import { logError, logInfo } from "../../utils/logger";

export default function ClinicPage() {
    const dispatch = useDispatch();
    const clinics = useSelector((state) => state.clinics.clinics);
    const status = useSelector((state) => state.clinics.status);
    const error = useSelector((state) => state.clinics.error);
    
    const [selectedClinicId, setSelectedClinicId] = useState(null);
    const [isEditClinicSlideOver, setIsEditClinicSlideOver] = useState(false);

    useEffect(() => {
        dispatch(fetchClinics())
            .unwrap()
            .then(() => {
                logInfo('Clinics fetched successfully', {
                    componentName: 'ClinicPage',
                    action: 'fetchClinics'
                });
            })
            .catch(error => {
                logError('Failed to fetch clinics', {
                    componentName: 'ClinicPage',
                    action: 'fetchClinics',
                    error: error.message
                });
            });
    }, [dispatch]);

    const handleClinicClick = (clinicId) => {
        setSelectedClinicId(clinicId);
        setIsEditClinicSlideOver(true);
        logInfo('Clinic selected for editing', {
            componentName: 'ClinicPage',
            action: 'selectClinic',
            clinicId
        });
    };

    const handleAddClinic = () => {
        logInfo('Add Clinic action initiated', {
            componentName: 'ClinicPage',
            action: 'addClinic'
        });
        // Implement add clinic logic here
    };

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className="pt-4">
                <Table
                    columns={[
                        { header: "Name", accessor: "name" },
                        { header: "Address", accessor: (row) => row.address?.street || 'N/A'},
                        { header: "Email", accessor: "email" },
                        { header: "Phone", accessor: "phoneNumber" },
                    ]}
                    data={clinics || []}
                    onRowClick={(row) => handleClinicClick(row._id)}
                    actions={[{ label: "Add Clinic", onClick: handleAddClinic }]}
                    title="Clinics"
                    description="A list of all clinics connected to your account."
                />
            </div>
            {isEditClinicSlideOver && selectedClinicId && (
                {/* <CliniceSlideOver
                    isOpen={isEditClinicSlideOver}
                    selectedClinicId={selectedClinicId}
                    onClose={() => {
                        setIsEditClinicSlideOver(false);
                        logInfo('Clinic edit slide over closed', {
                            componentName: 'ClinicPage',
                            action: 'closeSlideOver'
                        });
                    }}
                /> */}
            )}
        </>
    );
}
