import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Thunk to create a payment
export const createPayment = createAsyncThunk(
  "payments/createPayment",
  async (
    { invoiceId, paymentMethod, paymentAmount },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/payments`,
        { invoiceId, paymentMethod, amount: paymentAmount },
        config
      );

      // Include a success message in the return value
      console.log("Create payment thunk response:", response.data);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Thunk to fetch all payments
export const fetchAllPayments = createAsyncThunk(
  "payments/fetchAllPayments",
  async ({ page = 1, limit = 10 }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/payments`, {
        ...config,
        params: { page, limit }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Thunk to fetch payments for an invoice
export const fetchPaymentsByInvoice = createAsyncThunk(
  "payments/fetchPaymentsByInvoice",
  async (invoiceId, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/payments/${invoiceId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Thunk to edit a payment
export const editPayment = createAsyncThunk(
  "payments/editPayment",
  async (
    { paymentId, paymentMethod, paymentAmount, paymentDate },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/payments/${paymentId}`,
        { paymentMethod, amount: paymentAmount, paymentDate }, // Include paymentDate
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
