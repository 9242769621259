import React, { useEffect, useState } from "react";
import Table from "../../applicationUi/components/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../store/thunks/servicesThunk";
import EditServiceModal from "../services/EditServiceModal";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { fetchRoles } from "../../store/thunks/rolesThunk";

function ServicesPage() {
    const dispatch = useDispatch();
    const {
        services,
        pagination,
        status: serviceStatus,
        error: serviceError,
    } = useSelector((state) => state.services);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    console.log(services);

    useEffect(() => {
        dispatch(fetchServices({ page: 1, limit: pagination?.pageSize || 10 }));
        dispatch(fetchRoles());
    }, [dispatch, pagination?.pageSize]);

    const handleRowClick = (serviceId) => {
        const selectedService = services.find(
            (service) => service._id === serviceId
        );
        setIsServiceModalOpen(true);
        setSelectedService(selectedService);
        console.log("Clicked service:", JSON.stringify(selectedService));
    };

    const handleSearchClick = (service) => {
        setIsServiceModalOpen(true);
        setSelectedService(service);
    };

    const handleAddService = () => {
        setIsServiceModalOpen(true);
        setSelectedService(null); // Set to null for adding a new service
    };

    return (
        <div>
            <div className="m-8">
                <div className="text-base font-semibold leading-6 text-gray-900">
                    Search for clients by first name, last name, or email:
                </div>
                <SearchDropdown
                    prefix=":service:"
                    displayFields={[
                        "serviceCode",
                        "description",
                        "duration",
                        "price",
                    ].filter(field => field !== null)}
                    onSelection={(service) => handleSearchClick(service)}
                    placeholder={"Type to start searching..."}
                />
            </div>
            {serviceStatus === "loading" && <p>Loading services...</p>}
            {serviceStatus === "failed" && (
                <p>
                    Error loading services:{" "}
                    {typeof serviceError === "object"
                        ? JSON.stringify(serviceError)
                        : serviceError}
                </p>
            )}
            {serviceStatus === "succeeded" && services.length === 0 && (
                <p>No services available</p>
            )}
            {serviceStatus === "succeeded" && services.length > 0 && (
                <Table
                    columns={[
                        { header: "Service Code", accessor: "serviceCode" },
                        { header: "Description", accessor: "description" },
                        { header: "Duration", accessor: "duration" },
                        { header: "Price", accessor: "price" },
                        { header: "Color", accessor: "color" },
                    ]}
                    data={services}
                    onRowClick={handleRowClick}
                    actions={[
                        {
                            label: "Add Service",
                            onClick: handleAddService,
                        },
                    ]}
                    title="Services"
                    description="A list of all services offered by the company."
                    showPagination={pagination.totalPages > 1}
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={(page) =>
                        dispatch(
                            fetchServices({ page, limit: pagination.pageSize })
                        )
                    }
                />
            )}
            {isServiceModalOpen && (
                <EditServiceModal
                    service={selectedService} // Will be null for adding new service
                    onClose={() => {
                        setIsServiceModalOpen(false);
                        setSelectedService(null);
                    }}
                    onSave={() => {
                        setIsServiceModalOpen(false);
                        setSelectedService(null);
                        dispatch(
                            fetchServices({
                                page: 1,
                                limit: pagination?.pageSize || 10,
                            })
                        );
                    }}
                />
            )}
        </div>
    );
}

export default ServicesPage;
