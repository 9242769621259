import React, { useEffect, useState, useMemo } from "react";
import AddUser from "./AddUser";
import Table from "../../applicationUi/components/Table";
import { fetchUsers } from "../../store/thunks/userThunk";
import { useDispatch, useSelector } from "react-redux";
import UserDetails from "./UserDetails";
import { logInfo } from "../../../src/utils/logger";

function HRPage() {
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditUserOpen, setIsEditUserOpen] = useState(false);

    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users || []);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const handleUserAdded = () => {
        dispatch(fetchUsers());
        setIsAddUserOpen(false);
    };

    useEffect(() => {
        logInfo('HRPage component rendered', {
            componentName: 'HRPage',
            usersCount: users.length,
            isAddUserOpen: isAddUserOpen,
            isEditUserOpen: isEditUserOpen,
            selectedUserId: selectedUser?._id
        });
    }, [users, isAddUserOpen, isEditUserOpen, selectedUser]);

    const openAddUser = () => setIsAddUserOpen(true);
    const closeAddUser = () => setIsAddUserOpen(false);

    const openEditUser = (user) => {
        setSelectedUser(user);
        setIsEditUserOpen(true);
    };

    const closeEditUser = () => {
        setIsEditUserOpen(false);
        setSelectedUser(null);
    };

    const handleUserClick = (userId) => {
        console.log("Clicked user ID", userId);
        const user = users.find(u => u._id === userId);
        if (user) {
            openEditUser(user);
        }
    };

    const columns = useMemo(() => [
        { header: "First Name", accessor: "firstName" },
        { header: "Last Name", accessor: "lastName" },
        { header: "Role", accessor: "role" },
        { header: "Email", accessor: "email" },
        { header: "Phone", accessor: "phone" },
        { header: "Clinic Name", accessor: "defaultLocation.name" },
        { header: "Qualifications", accessor: "qualifications" },
    ], []);

    return (
        <>
            <div>
                <div className="mb-10"></div>
                {!isAddUserOpen && !isEditUserOpen && (
                    <Table
                        columns={columns}
                        data={users}
                        onRowClick={handleUserClick}
                        actions={[
                            {
                                label: "Add User",
                                onClick: openAddUser,
                            },
                        ]}
                        title="Users"
                        description="A list of all users connected to your account."
                    />
                )}
            </div>

            {isAddUserOpen && <AddUser onClose={closeAddUser} onUserAdded={handleUserAdded} />}
            {isEditUserOpen && selectedUser && (
                <UserDetails user={selectedUser} onClose={closeEditUser} />
            )}
        </>
    );
}

export default HRPage;
