import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { modifyUser } from "../../../store/thunks/userThunk";
import BankingInstitutionNumberTable from "../../../utils/BankingInstitutionNumberTable";

const UserFinancialInformation = ({ user }) => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            bankName: user.bankName || "",
            institutionNumber: user.institutionNumber || "",
            accountNumber: user.accountNumber || "",
            branchNumber: user.branchNumber || "",
            socialInsuranceNumber: user.socialInsuranceNumber || "",
        },
        validationSchema: Yup.object({
            bankName: Yup.string().required("Required"),
            institutionNumber: Yup.string().required("Required"),
            accountNumber: Yup.string().required("Required"),
            branchNumber: Yup.string().required("Required"),
            socialInsuranceNumber: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            dispatch(modifyUser({ ...user, ...values }));
        },
    });

    const handleBankChange = (event) => {
        const selectedBank = event.target.value;
        const bankingTable = BankingInstitutionNumberTable();
        const selectedInstitution = bankingTable.props.children.props.children[1].props.children.find(
            bank => bank.props.children[0].props.children === selectedBank
        );
        formik.setFieldValue("bankName", selectedBank);
        formik.setFieldValue("institutionNumber", selectedInstitution ? selectedInstitution.props.children[1].props.children : "");
    };

    return (
        <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label
                        htmlFor="bankName"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Bank Name
                    </label>
                    <div className="mt-1">
                        <select
                            name="bankName"
                            id="bankName"
                            onChange={handleBankChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bankName}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                            <option value="">Select a bank</option>
                            {BankingInstitutionNumberTable().props.children.props.children[1].props.children.map((bank) => (
                                <option key={bank.key} value={bank.props.children[0].props.children}>
                                    {bank.props.children[0].props.children}
                                </option>
                            ))}
                        </select>
                    </div>
                    {formik.touched.bankName && formik.errors.bankName ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.bankName}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="institutionNumber"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Institution Number
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="institutionNumber"
                            id="institutionNumber"
                            {...formik.getFieldProps("institutionNumber")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            readOnly
                        />
                    </div>
                    {formik.touched.institutionNumber && formik.errors.institutionNumber ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.institutionNumber}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="accountNumber"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Account Number
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="accountNumber"
                            id="accountNumber"
                            {...formik.getFieldProps("accountNumber")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {formik.touched.accountNumber &&
                    formik.errors.accountNumber ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.accountNumber}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="branchNumber"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Branch Number
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="branchNumber"
                            id="branchNumber"
                            {...formik.getFieldProps("branchNumber")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {formik.touched.branchNumber &&
                    formik.errors.branchNumber ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.branchNumber}
                        </div>
                    ) : null}
                </div>

                <div className="sm:col-span-3">
                    <label
                        htmlFor="socialInsuranceNumber"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Social Insurance Number
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="socialInsuranceNumber"
                            id="socialInsuranceNumber"
                            {...formik.getFieldProps("socialInsuranceNumber")}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {formik.touched.socialInsuranceNumber && formik.errors.socialInsuranceNumber ? (
                        <div className="text-red-500 text-sm mt-1">
                            {formik.errors.socialInsuranceNumber}
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
};

export default UserFinancialInformation;
