import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { modifyUser } from '../../../store/thunks/userThunk';
import { fetchClinics } from '../../../store/thunks/clinicThunk';

const UserAvailabilityInformation = ({ user }) => {
  const dispatch = useDispatch();
  const { clinics } = useSelector((state) => state.clinics);
  const [selectedClinic, setSelectedClinic] = useState(null);

  useEffect(() => {
    dispatch(fetchClinics());
  }, [dispatch]);

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const initialAvailability = user.availability || clinics.reduce((acc, clinic) => {
    acc[clinic._id] = daysOfWeek.reduce((dayAcc, day) => {
      dayAcc[day] = [{ start: '', end: '' }];
      return dayAcc;
    }, {});
    return acc;
  }, {});

  const formik = useFormik({
    initialValues: {
      availability: initialAvailability,
    },
    validationSchema: Yup.object({
      availability: Yup.object().shape(
        clinics.reduce((acc, clinic) => {
          acc[clinic._id] = Yup.object().shape(
            daysOfWeek.reduce((dayAcc, day) => {
              dayAcc[day] = Yup.array().of(
                Yup.object().shape({
                  start: Yup.string().matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format'),
                  end: Yup.string().matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format'),
                })
              );
              return dayAcc;
            }, {})
          );
          return acc;
        }, {})
      ),
    }),
    onSubmit: (values) => {
      dispatch(modifyUser({ id: user._id, userData: { availability: values.availability } }));
    },
  });

  const handleAddTimeSlot = (clinicId, day) => {
    const updatedAvailability = { ...formik.values.availability };
    updatedAvailability[clinicId][day].push({ start: '', end: '' });
    formik.setFieldValue('availability', updatedAvailability);
  };

  const handleRemoveTimeSlot = (clinicId, day, index) => {
    const updatedAvailability = { ...formik.values.availability };
    updatedAvailability[clinicId][day].splice(index, 1);
    formik.setFieldValue('availability', updatedAvailability);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Select Clinic</label>
        <select
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          onChange={(e) => setSelectedClinic(e.target.value)}
          value={selectedClinic || ''}
        >
          <option value="">Select a clinic</option>
          {clinics.map((clinic) => (
            <option key={clinic._id} value={clinic._id}>
              {clinic.name}
            </option>
          ))}
        </select>
      </div>

      {selectedClinic && (
        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Set Availability</h3>
          {daysOfWeek.map((day) => (
            <div key={day} className="mb-4">
              <h4 className="text-md font-medium text-gray-700 mb-2">{day}</h4>
              {formik.values.availability[selectedClinic][day].map((slot, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                  <input
                    type="time"
                    name={`availability.${selectedClinic}.${day}[${index}].start`}
                    value={slot.start}
                    onChange={formik.handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <span>to</span>
                  <input
                    type="time"
                    name={`availability.${selectedClinic}.${day}[${index}].end`}
                    value={slot.end}
                    onChange={formik.handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveTimeSlot(selectedClinic, day, index)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddTimeSlot(selectedClinic, day)}
                className="mt-2 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Time Slot
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserAvailabilityInformation;
