import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAppointmentsList } from "../../../store/thunks/appointmentsThunk";
import DashboardCards from "./DashboardCards";
import WaagChart from "./WaagChart";
import { fetchWaagData } from '../../../store/thunks/waagThunk';

export default function OwnerDashboardComponent({
    clinician,
    handleClientClick,
    handleAppointmentClick,
    handleReschedule, // Add this prop
}) {
    const dispatch = useDispatch();
    const appointments = useSelector(
        (state) => state.appointments.appointments || []
    );
    const waagData = useSelector((state) => state.waag.data);
    // const { clinician: clinicianDetails, appointments: appointmentData } =
    //   useSelector(
    //     (state) => state.appointments || { clinician: {}, appointments: [] }
    //   );

    // const { appointments } = appointmentData;
    const [filteredAppointments, setFilteredAppointments] = useState([]);

    useEffect(() => {
        if (appointments) {
            setFilteredAppointments(appointments);
        }
    }, [appointments]);

    useEffect(() => {
        setFilteredAppointments(appointments);
    }, [appointments]);

    const handleFilterAppointments = (filterFunction) => {
        setFilteredAppointments(filterFunction(appointments));
    };

    useEffect(() => {
        dispatch(fetchAppointmentsList());
    }, [dispatch, clinician]);

    
  useEffect(() => {
    dispatch(fetchWaagData());
  }, [dispatch]);


    return (
        <div className="grid grid-cols-3 gap-6 pb-20">
            <div className="col-span-3">
                <h3>OWNER DASHBOARD</h3>
                <DashboardCards appointments={appointments} waagData={waagData} />
                <WaagChart waagData={waagData} />
            </div>
        </div>
    );
}
