// src/store/slices/clientsSlice.js

// src/store/slices/clientsSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  createClient,
  fetchClients,
  fetchClientById,
  updateClient,
  deleteClient,
} from "../thunks/clientsThunk";

const clientsSlice = createSlice({
  name: "clients",
  initialState: {
    clients: [],
    status: "idle",
    error: null,
    selectedClient: null,
    wip: {},
    pagination: {
      totalClients: 0,
      currentPage: 1,
      totalPages: 1,
      pageSize: 20,
    },
  },
  reducers: {
    setClient: (state, action) => {
      state.wip = action.payload;
    },
    getClient: (state, action) => {
      return state.wip;
    },
    clearSelectedClient: (state) => {
      state.selectedClient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Client
      .addCase(createClient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clients.push(action.payload);
      })
      .addCase(createClient.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch All Clients
      .addCase(fetchClients.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clients = action.payload.clients;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch Client by ID
      .addCase(fetchClientById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClientById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedClient = action.payload;
      })
      .addCase(fetchClientById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Update Client
      .addCase(updateClient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.clients.findIndex(
          (client) => client._id === action.payload._id
        );
        if (index !== -1) {
          state.clients[index] = action.payload;
        }
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Delete Client
      .addCase(deleteClient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clients = state.clients.filter(
          (client) => client._id !== action.meta.arg
        );
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setClient, getClient, clearSelectedClient } = clientsSlice.actions;
export default clientsSlice.reducer;
