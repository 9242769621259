import { createSlice } from "@reduxjs/toolkit";
import {
  createPayment,
  fetchPaymentsByInvoice,
  fetchAllPayments,
} from "../thunks/paymentsThunk";

const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    payments: [],
    status: "idle",
    error: null,
    currentPage: 1,
    totalPages: 1,
    totalPayments: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle payment creation
      .addCase(createPayment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payments.push(action.payload);
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Handle fetching payments by invoice
      .addCase(fetchPaymentsByInvoice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPaymentsByInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payments = action.payload;
      })
      .addCase(fetchPaymentsByInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAllPayments.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAllPayments.fulfilled, (state, action) => {
        console.log("payload", action.payload);
        state.status = "succeeded";
        state.payments = action.payload.payments;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.totalPayments = action.payload.totalPayments;
      })
      .addCase(fetchAllPayments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default paymentsSlice.reducer;
