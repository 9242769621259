// src/pages/hrPage/AddUser.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../store/thunks/userThunk";
import { generateRandomPassword } from "../../utils/generateRandomPassword";
import { isValidEmail } from "../../utils/validation";
import { logInfo, logError, logWarn } from "../../utils/logger";
import GoogleMapsAutocomplete from "../../applicationUi/components/GoogleAutoComplete";

const AddUser = ({ onClose, onUserAdded }) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.users.loading);
    const error = useSelector((state) => state.users.error);
    const [password, setPassword] = useState(generateRandomPassword());
    const [emailError, setEmailError] = useState("");

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumbers: [{ type: "", number: "" }],
        employeeId: "",
        role: [],
        specialization: [],
        address: {
            aptNumber: "",
            street: "",
            city: "",
            province: "",
            postalCode: "",
        },
        startDate: "",
        terminationDate: "",
        jobTitle: "",
        qualifications: "",
        calendarColor: "",
        calendarDisplayName: "",
        doubleBookingAllowed: false,
        showClientArrivedNotification: true,
        showClientReadyNotification: false,
        onlineBookingEnabled: false,
    });

    useEffect(() => {
        setFormData((prevState) => ({ ...prevState, password }));
        logInfo("AddUser: Password generated and set in form data", {
            component: "AddUser",
            passwordLength: password.length,
        });
    }, [password]);

    useEffect(() => {
        logInfo("AddUser component rendered", {
            component: "AddUser",
            emailError: emailError ? "Present" : "None",
        });
    }, [formData, emailError, password]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === "email") {
            const isValid = isValidEmail(value);
            setEmailError(isValid ? "" : "Invalid email format");
            if (!isValid) {
                logWarn("AddUser: Invalid email format entered", {
                    component: "AddUser",
                    email: value,
                });
            } else {
                logInfo("AddUser: Valid email entered", {
                    component: "AddUser",
                    email: value,
                });
            }
        }
        if (type === "select-one") {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name.startsWith("address.")) {
            const addressField = name.split(".")[1];
            setFormData((prevState) => ({
                ...prevState,
                address: {
                    ...prevState.address,
                    [addressField]: value,
                },
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
        logInfo("AddUser: Form field updated", {
            component: "AddUser",
            field: name,
            value: type === "checkbox" ? checked : value,
        });
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    };

    const handlePhoneNumberChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPhoneNumbers = [...formData.phoneNumbers];
        if (name === "number") {
            updatedPhoneNumbers[index][name] = formatPhoneNumber(value);
        } else {
            updatedPhoneNumbers[index][name] = value;
        }
        setFormData({ ...formData, phoneNumbers: updatedPhoneNumbers });
        logInfo("AddUser: Phone number updated", {
            component: "AddUser",
            index,
            [name]: updatedPhoneNumbers[index][name],
        });
    };

    const addPhoneNumber = () => {
        setFormData((prevState) => ({
            ...prevState,
            phoneNumbers: [...prevState.phoneNumbers, { type: "", number: "" }],
        }));
        logInfo("AddUser: New phone number field added", {
            component: "AddUser",
            totalPhoneNumbers: formData.phoneNumbers.length + 1,
        });
    };

    const handleRoleChange = (role) => {
        setFormData((prevState) => ({
            ...prevState,
            role: prevState.role.includes(role)
                ? prevState.role.filter((r) => r !== role)
                : [...prevState.role, role],
        }));
        logInfo("AddUser: Role updated", {
            component: "AddUser",
            role,
            action: formData.role.includes(role) ? "removed" : "added",
        });
    };

    const handleSpecializationChange = (specialization) => {
        setFormData((prevState) => ({
            ...prevState,
            specialization: prevState.specialization.includes(specialization)
                ? prevState.specialization.filter((s) => s !== specialization)
                : [...prevState.specialization, specialization],
        }));
        logInfo("AddUser: Specialization updated", {
            component: "AddUser",
            specialization,
            action: formData.specialization.includes(specialization)
                ? "removed"
                : "added",
        });
    };

    const validateForm = () => {
        let isValid = true;
        if (!isValidEmail(formData.email)) {
            setEmailError("Invalid email format");
            logError("AddUser: Form validation failed", {
                component: "AddUser",
                reason: "Invalid email format",
                email: formData.email,
            });
            isValid = false;
        }
        // Add more validation as needed
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            dispatch(createUser(formData))
                .then(() => {
                    logInfo("AddUser: User creation successful", {
                        component: "AddUser",
                        email: formData.email,
                        roles: formData.role,
                    });
                    onUserAdded(); // Trigger state refresh on calling component
                    onClose(); // Close the window on success
                })
                .catch((error) => {
                    logError("AddUser: User creation failed", {
                        component: "AddUser",
                        error: error.message,
                    });
                });
        } else {
           logWarn(
                "AddUser: Form submission prevented due to validation errors",
                { component: "AddUser", formErrors: { email: emailError } }
            );
        }
    };

    return (
        <div className="p-6 bg-white shadow rounded-lg">
            <div className="flex flex-col">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold">Create New User</h1>
                    <button
                        onClick={() => {
                            onClose();
                            logInfo("AddUser: Add User form closed", {
                                component: "AddUser",
                            });
                        }}
                        className="inline-flex justify-center px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Close
                    </button>
                </div>
                {error && <p className="text-red-500 mb-4">{error.message || error}</p>}
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Email and Password */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`mt-1 block w-full px-3 py-2 border ${
                                    emailError
                                        ? "border-red-500"
                                        : "border-gray-300"
                                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                required
                            />
                            {emailError && (
                                <p className="text-red-500 text-xs mt-1">
                                    {emailError}
                                </p>
                            )}
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Random Temporary Password
                            </label>
                            <input
                                type="text"
                                name="password"
                                value={formData.password}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    {/* Name and Phone Numbers */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                First Name
                            </label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    {formData.phoneNumbers.map((phone, index) => (
                        <div key={index} className="flex flex-wrap gap-4">
                            <div className="flex-1 min-w-[200px]">
                                <label className="block text-sm font-medium text-gray-700">
                                    Phone Number {index + 1}
                                </label>
                                <input
                                    type="text"
                                    name="number"
                                    value={phone.number}
                                    onChange={(e) =>
                                        handlePhoneNumberChange(index, e)
                                    }
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="Phone number"
                                />
                            </div>
                            <div className="flex-1 min-w-[200px]">
                                <label className="block text-sm font-medium text-gray-700">
                                    Type
                                </label>
                                <select
                                    name="type"
                                    value={phone.type}
                                    onChange={(e) =>
                                        handlePhoneNumberChange(index, e)
                                    }
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option value="">Select type</option>
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Mobile">Mobile</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    ))}

                    <button
                        type="button"
                        onClick={addPhoneNumber}
                        className="mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Add Phone Number
                    </button>

                    {/* Address and Employee ID */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Address
                            </label>
                            <GoogleMapsAutocomplete
                                onPlaceSelected={(place) => {
                                    const addressComponents = place.address_components;
                                    const streetNumber = addressComponents.find(component => component.types.includes('street_number'))?.long_name || '';
                                    const streetName = addressComponents.find(component => component.types.includes('route'))?.long_name || '';
                                    const city = addressComponents.find(component => component.types.includes('locality'))?.long_name || '';
                                    const province = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '';
                                    const postalCode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name || '';

                                    setFormData(prevState => ({
                                        ...prevState,
                                        address: {
                                            ...prevState.address,
                                            street: `${streetNumber} ${streetName}`.trim(),
                                            city,
                                            province,
                                            postalCode
                                        }
                                    }));
                                }}
                                inputId="address-input"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Street
                            </label>
                            <input
                                type="text"
                                name="address.street"
                                value={formData.address.street}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Street"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                City
                            </label>
                            <input
                                type="text"
                                name="address.city"
                                value={formData.address.city}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="City"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Province
                            </label>
                            <input
                                type="text"
                                name="address.province"
                                value={formData.address.province}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Province"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Postal Code
                            </label>
                            <input
                                type="text"
                                name="address.postalCode"
                                value={formData.address.postalCode}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Postal Code"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Employee ID
                            </label>
                            <input
                                type="text"
                                name="employeeId"
                                value={formData.employeeId}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Employee ID"
                            />
                        </div>
                    </div>

                    {/* Role and Specialization */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Role
                            </label>
                            <div className="mt-2 space-y-2">
                                {[
                                    "clinician",
                                    "admin",
                                    "owner",
                                    "hr",
                                    "officeManager",
                                    "bookkeeper",
                                ].map((role) => (
                                    <div
                                        key={role}
                                        className="flex items-center"
                                    >
                                        <input
                                            type="checkbox"
                                            id={role}
                                            name="role"
                                            value={role}
                                            checked={formData.role.includes(
                                                role
                                            )}
                                            onChange={() =>
                                                handleRoleChange(role)
                                            }
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                        />
                                        <label
                                            htmlFor={role}
                                            className="ml-2 block text-sm text-gray-900 capitalize"
                                        >
                                            {role}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {formData.role.includes("clinician") && (
                            <div className="flex-1 min-w-[200px]">
                                <label className="block text-sm font-medium text-gray-700">
                                    Specialization
                                </label>
                                <div className="mt-2 space-y-2">
                                    {["SLP", "CDA"].map((specialization) => (
                                        <div
                                            key={specialization}
                                            className="flex items-center"
                                        >
                                            <input
                                                type="checkbox"
                                                id={specialization}
                                                name="specialization"
                                                value={specialization}
                                                checked={
                                                    formData.specialization &&
                                                    formData.specialization.includes(
                                                        specialization
                                                    )
                                                }
                                                onChange={() =>
                                                    handleSpecializationChange(
                                                        specialization
                                                    )
                                                }
                                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                            />
                                            <label
                                                htmlFor={specialization}
                                                className="ml-2 block text-sm text-gray-900"
                                            >
                                                {specialization}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Conditional Fields for Clinician Role */}
                    {formData.role.includes("clinician") && (
                        <>
                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Job Title
                                    </label>
                                    <input
                                        type="text"
                                        name="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="Job Title"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Qualifications
                                    </label>
                                    <input
                                        type="text"
                                        name="qualifications"
                                        value={formData.qualifications}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="Qualifications"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Calendar Color
                                    </label>
                                    <input
                                        type="color"
                                        name="calendarColor"
                                        value={formData.calendarColor}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Calendar Display Name
                                    </label>
                                    <input
                                        type="text"
                                        name="calendarDisplayName"
                                        value={formData.calendarDisplayName}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="Calendar Display Name"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Double Booking Allowed
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="doubleBookingAllowed"
                                        checked={formData.doubleBookingAllowed}
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Show Client Arrived Notification
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="showClientArrivedNotification"
                                        checked={
                                            formData.showClientArrivedNotification
                                        }
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Show Client Ready Notification
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="showClientReadyNotification"
                                        checked={
                                            formData.showClientReadyNotification
                                        }
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Online Booking Enabled
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="onlineBookingEnabled"
                                        checked={formData.onlineBookingEnabled}
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {/* Dates */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Start Date
                            </label>
                            <input
                                type="date"
                                name="startDate"
                                value={formData.startDate}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Termination Date
                            </label>
                            <input
                                type="date"
                                name="terminationDate"
                                value={formData.terminationDate}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end space-x-4 mt-6">
                        <button
                            type="button"
                            onClick={() => {
                                onClose();
                                logInfo("AddUser: Add User form cancelled", {
                                    component: "AddUser",
                                });
                            }}
                            className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {loading ? "Creating..." : "Create User"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddUser;
