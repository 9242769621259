import { createSlice } from '@reduxjs/toolkit';
import { fetchClinics, fetchClinicById, createClinic, updateClinic, deleteClinic } from '../thunks/clinicThunk';

export const clinicSlice = createSlice({
  name: 'clinics',
  initialState: {
    clinics: [],
    status: "idle",
    error: null,
    selectedClinic: null,
  },
  reducers: {}, // You might add other reducers here for local state changes
  extraReducers(builder) {
    builder
      // Fetch all clinics
      .addCase(fetchClinics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClinics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinics = action.payload;
      })
      .addCase(fetchClinics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Fetch single clinic
      .addCase(fetchClinicById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClinicById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedClinic = action.payload;
      })
      .addCase(fetchClinicById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Create clinic
      .addCase(createClinic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createClinic.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinics.push(action.payload);
      })
      .addCase(createClinic.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update clinic
      .addCase(updateClinic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateClinic.fulfilled, (state, action) => { 
        state.status = 'succeeded';
        const index = state.clinics.findIndex(clinic => clinic._id === action.payload._id);
        if (index !== -1) {
          state.clinics[index] = action.payload;
        }
      })
      .addCase(updateClinic.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Delete clinic
      .addCase(deleteClinic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteClinic.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinics = state.clinics.filter(clinic => clinic._id !== action.payload);
      })
      .addCase(deleteClinic.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default clinicSlice.reducer;
